const moreBlocks = document.querySelectorAll("[data-more-block]");

for (const moreBlock of moreBlocks) {
    const moreBtn = moreBlock.querySelector("[data-more-btn]");
    const moreBtnSpan = moreBlock.querySelector("[data-more-span]");
    const blockValue = parseInt(moreBlock.getAttribute("data-more-block"));

    function showElements() {
        const hiddenItems = moreBlock.querySelectorAll(
            "[data-more-item]:not(.shown)"
        );

        for (let i = 0; i < Math.min(blockValue, hiddenItems.length); i++) {
            hiddenItems[i].classList.add("shown");
            hiddenItems[i].style.display = "block";
        }

        if (moreBtnSpan && hiddenItems.length - blockValue < blockValue) {
            moreBtnSpan.textContent = hiddenItems.length - blockValue;
        }

        if (moreBtn && hiddenItems.length <= blockValue) {
            moreBtn.style.display = "none";
        }
    }

    showElements();

    if (moreBtn) {
        moreBtn.addEventListener("click", function() {
            showElements();
        });
    }
}
