const vModalFeedback = document.querySelector("[data-vmodal-feedback]");
const vModalFeedbackBtn = document.querySelectorAll("[data-vmodal-feedback-btn]");

vModalFeedbackBtn.forEach((feedbackBtn)=>{
  const feedbackBlock = feedbackBtn.closest("[data-vmodal-feedback-block]");
  let feedbackContent = feedbackBlock.querySelector("[data-vmodal-feedback-content]").innerHTML;
  feedbackContent = feedbackContent.replaceAll("text-line-clamp-7", "");

  feedbackBtn.addEventListener("click", ()=>{
    vModalFeedback.innerHTML = feedbackContent;
  });
});
